import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./starterKit.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck
} from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function StarterKit() {

  const data = useStaticQuery(graphql`
    query {
      logoGrey: file(relativePath: { eq: "drops-logo-grey.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 76, height: 30, placeholder: NONE)
        }
      },
      dailyHabitsKit: file(relativePath: { eq: "starterkit.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      },
    }
  `)

  return (
    <div className={styles.mobileBorder}>
        <Container className={styles.kitSection}>
          <Row>
            <Col lg={6} className="px-0">
              <GatsbyImage loading="eager" className={styles.logoImg} style={{maxHeight: '40px'}} image={data.logoGrey.childImageSharp.gatsbyImageData} alt="Drops Logo Grey" />
              <h2 className={styles.kitTitle}>Starter Kit</h2>
              <GatsbyImage loading="eager" className={`${styles.mobileImg} d-xs-block d-lg-none`} image={data.dailyHabitsKit.childImageSharp.gatsbyImageData} alt="Daily Habits Kit" />
              <p className={styles.kitDescription}>Umsteigen leicht gemacht. Das perfekte Einsteigerset für eine nachhaltige Zahnpflege.</p>
              <Row className={styles.keypointsSection}>
                <Col lg={6} xs={6} className={styles.mobileColumn}>
                  <div className={`${styles.keypointRow} align-items-center d-flex`}>
                    <FontAwesomeIcon icon={faCheck} className={`small text-black ${styles.checkmark}`} />
                    <span className="fw-bold">62 Drops für 1 Monat</span>
                  </div>
                  <div className={`${styles.keypointRow} align-items-center d-flex`}>
                    <FontAwesomeIcon icon={faCheck} className={`small text-black ${styles.checkmark}`} />
                    <span>1x Zungenschaber</span>
                  </div>
                  <div className={`${styles.keypointRow} align-items-center d-flex`}>
                    <FontAwesomeIcon icon={faCheck} className={`small text-black ${styles.checkmark}`} />
                    <span>1x Zahnseide</span>
                  </div>
                  <div className={`${styles.keypointRow} align-items-center d-flex`}>
                    <FontAwesomeIcon icon={faCheck} className={`small text-black ${styles.checkmark}`} />
                    <span>1x Aufbewahrungsglas</span>
                  </div>
                </Col>
                <Col lg={6} xs={6} className={`pl-5 ${styles.mobileColumn} pl-3`}>
                  <div className={`${styles.keypointRow} align-items-center d-flex`}>
                    <FontAwesomeIcon icon={faCheck} className={`small text-black ${styles.checkmark}`} />
                    <span>1x Reise Tin</span>
                  </div>
                  <div className={`${styles.keypointRow} align-items-center d-flex`}>
                    <FontAwesomeIcon icon={faCheck} className={`small text-black ${styles.checkmark}`} />
                    <span>1x Wechselzahnbürste</span>
                  </div>
                  <div className={`${styles.keypointRow} align-items-center d-flex`}>
                    <FontAwesomeIcon icon={faCheck} className={`small text-black ${styles.checkmark}`} />
                    <span>1x Zahnbürsten Etui</span>
                  </div>
                </Col>
              </Row>
              <a className={styles.viewMoreCTA} href="/products/starterkit">
                <span className={styles.CTABody}>
                  <span className={styles.CTALeft}>19,95€</span>
                  <span>Jetzt mehr erfahren</span>
                </span>
              </a>
            </Col>
            <Col lg={6}>
              <GatsbyImage loading="eager" className="d-none d-lg-block" image={data.dailyHabitsKit.childImageSharp.gatsbyImageData} alt="Daily Habits Kit" />
            </Col>
          </Row>
        </Container>
		</div>
  )
}
