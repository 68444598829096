import React from "react"

import * as styles from "./sustainability.module.scss"
import sideGIF from '../../images/GIF_Versandumschlag.gif'

import Container from 'react-bootstrap/Container'

export default function Sustainability() {

  return (
    <div>
        <Container className={styles.sustainability}>
          <div className="d-flex d-lg-none justify-content-center">
            <img className={styles.sustainableGif} src={sideGIF} alt="Sustainability" />
          </div>
          <h2>Warum Nachhaltigkeit wichtig ist</h2>
          <p>Erfahre mehr über uns und unsere Mission für die Umwelt</p>
          <a href="/pages/aboutus" className={styles.learnMoreCTA}>Mehr erfahren</a>
          <div className="d-lg-flex d-none justify-content-center">
            <img className={styles.sustainableGif} src={sideGIF} alt="Sustainability" />
          </div>
        </Container>
		</div>
  )
}
