import React from "react"

import Layout from "../components/layout/layout"
import Header from "../components/index/header"
import StarterKit from "../components/index/starterKit"
import IconSection from "../components/index/iconSection"
import ProductCategories from "../components/index/productCategories"
import Sustainability from "../components/index/sustainability"
import TrialSection from "../components/index/trialSection"
import ReviewSection from "../components/index/reviewSection"

import CookieConsent from "react-cookie-consent";

import Seo from "../components/layout/seo";

import useIsClient from '../useIsClient';

export default function Home() {

  const { isClient, key } = useIsClient();

  if ( !isClient ) return null;

  return (
    <Layout key={key} showBottom="false" navStyle="light">
      <Seo title="Drops | Natürliche Minze | Toothpaste Drops" path="/" description="Über 5000 zufriedene Kunden - Natürlich - Plastikfrei - Ohne Tierversuche - Vegan - Zahnpasta Drops. Hilf uns den Plastikmüll in unserer Umwelt & den Meeren zu beenden. #weltretter" />
      <Header />
      <StarterKit />
      <IconSection firstTitle="4.9" firstText={<>> 500 positive <br />Rückmeldungen</>} firstStar={true} secondTitle="0%" secondText={<>Plastik <br />innen & außen</>} thirdTitle="100%" thirdText={<>Made in Germany <br />& Made by Us</>} fourthTitle="2x" fourthText={<>alle Sorten mit und <br />ohne Fluorid</>} />
      <ProductCategories />
      <Sustainability />
      <IconSection firstTitle="400 Mio." firstText={<>Plastiktuben jedes Jahr <br />in Deutschland</>} firstStar={false} secondTitle=">50 Jahre" secondText={<>bis eine Tube im <br />Meer zersetzt wird</>} thirdTitle="1.000.000" thirdText={<>Meeresvögel sterben<br /> jedes Jahr an Plastik</>} fourthTitle="18.000" fourthText={<>Plastikteile je km² <br />Meeresoberfläche</>} />
      <TrialSection />
      <ReviewSection />
      <CookieConsent style={{ background: "#000000", color: "#ffffff", fontSize: "14px", alignItems: "center" }} contentClasses="m-2" buttonClasses="m-2" buttonStyle={{ color: "#ffffff", background: "#0275d8", fontSize: "13px" }} buttonText="OK">Zur Bereitstellung von Diensten verwenden wir Cookies. Durch die Nutzung dieser Website stimmen Sie zu.</CookieConsent>
    </Layout>
  )
}
