// extracted by mini-css-extract-plugin
export var trialSection = "trialSection-module--trialSection--1JMFp";
export var upperBox = "trialSection-module--upperBox--d7BBr";
export var trial = "trialSection-module--trial--qjac1";
export var trialTitle = "trialSection-module--trialTitle--aE1cL";
export var trialDescription = "trialSection-module--trialDescription--pSJGC";
export var lowerBox = "trialSection-module--lowerBox--JtBEY";
export var infoText = "trialSection-module--infoText--pNeV0";
export var ctaBox = "trialSection-module--ctaBox--K-Y5u";
export var ctaBtn = "trialSection-module--ctaBtn--eCs7n";
export var keypointsBox = "trialSection-module--keypointsBox--efpd3";
export var mobileColumn = "trialSection-module--mobileColumn--aVFrh";