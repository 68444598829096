import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./trialSection.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck
} from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function TrialSection() {

  const data = useStaticQuery(graphql`
    query {
      trialPackage: file(relativePath: { eq: "trial.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 300, placeholder: NONE)
        }
      },
    }
  `)

  return (
    <div className={styles.trialSection}>
      <div className={styles.upperBox}>
        <Container>
          <Row className="align-items-center">
            <Col lg={{ span: 5, offset: 1 }}>
              <GatsbyImage loading="eager" className="mw-100" image={data.trialPackage.childImageSharp.gatsbyImageData} alt="Trial Package" />
            </Col>
            <Col lg={6} className={styles.trial}>
              <h2 className={styles.trialTitle}>Ganz ohne Risiko testen.</h2>
              <p className={styles.trialDescription}>Für dein neues Zahnputzerlebnis bieten wir dir ein 100% plastikfreies <b>Probierpaket für nur 8,95€</b> an.</p>
              <Row className={styles.keypointsBox}>
                <Col lg={6} xs={6} className={styles.mobileColumn}>
                  <div className="my-2 align-items-center d-flex">
                    <FontAwesomeIcon icon={faCheck} className="small mr-2 text-black" />
                    <span>20 Drops für 10 Tage</span>
                  </div>
                  <div className="my-2 align-items-center d-flex">
                    <FontAwesomeIcon icon={faCheck} className="small mr-2 text-black" />
                    <span>1x Bambuszahnbürste</span>
                  </div>
                  <div className="my-2 align-items-center d-flex">
                    <FontAwesomeIcon icon={faCheck} className="small mr-2 text-black" />
                    <span>1x Reisedose</span>
                  </div>
                  <div className="my-2 align-items-center d-flex">
                    <FontAwesomeIcon icon={faCheck} className="small mr-2 text-black" />
                    <span>Versandkostenfrei</span>
                  </div>
                </Col>
                <Col lg={6} xs={6} className={`pl-5 ${styles.mobileColumn} pl-3`}>
                  <div className="my-2 align-items-center d-flex">
                    <FontAwesomeIcon icon={faCheck} className="small mr-2 text-black" />
                    <span>100% Plastikfrei</span>
                  </div>
                  <div className="my-2 align-items-center d-flex">
                    <FontAwesomeIcon icon={faCheck} className="small mr-2 text-black" />
                    <span>In den Briefkasten</span>
                  </div>
                  <div className="my-2 align-items-center d-flex">
                    <FontAwesomeIcon icon={faCheck} className="small mr-2 text-black" />
                    <span>Schnelle Lieferung</span>
                  </div>
                  <div className="my-2 align-items-center d-flex">
                    <FontAwesomeIcon icon={faCheck} className="small mr-2 text-black" />
                    <span>Weltretter Status</span>
                  </div>
                </Col>
              </Row>
              <div className="d-block d-lg-none text-center">
                <a href="/products/probierpaket" className={`${styles.ctaBtn}`}>Jetzt für 8,95€ testen</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.lowerBox}>
        <Container>
          <Row>
            <Col lg={{ span: 7, offset: 1 }}>
              <p className={styles.infoText}>Das Probierpaket landet ganz entspannt in deinem Briefkasten. Du musst dir also keine Sorgen um die Zustellung machen. <a href="/products/probierpaket"><b>Jetzt mehr erfahren</b></a>.</p>
            </Col>
            <Col lg={4} className={`${styles.ctaBox} d-none d-lg-block`}>
              <a href="/products/probierpaket" className={styles.ctaBtn}>Jetzt für 8,95€ testen</a>
            </Col>
          </Row>
        </Container>
      </div>
		</div>
  )
}
