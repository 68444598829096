import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./productCategories.module.scss"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss'

export default function ProductCategories() {

  const data = useStaticQuery(graphql`
    query {
      dropsCategory: file(relativePath: { eq: "drops_category.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 650, placeholder: NONE)
        }
      },
      addonsCategory: file(relativePath: { eq: "zubehoer_category.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 650, placeholder: NONE)
        }
      },
      dropsCategoryMobile: file(relativePath: { eq: "drops_category.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 350, width: 337, placeholder: NONE)
        }
      },
      addonsCategoryMobile: file(relativePath: { eq: "zubehoer_category.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 350, width: 337, placeholder: NONE)
        }
      },
      probierpaketCategoryMobile: file(relativePath: { eq: "probierpaket_category.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 350, width: 337, placeholder: NONE)
        }
      },
      starterkitCategoryMobile: file(relativePath: { eq: "starterkit_category.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 350, width: 337, placeholder: NONE)
        }
      },
      monatspaketReview: allDropsReview(
        sort: {fields: createdAt, order: DESC}
        filter: {curated: {eq: true}, productIds: {ids: {in: ["6899851854007"]}}, rating: {gte: 1}}
      ) {
        totalCount
        sum(field: rating)
      },
      starterkitReview: allDropsReview(
        sort: {fields: createdAt, order: DESC}
        filter: {curated: {eq: true}, productIds: {ids: {in: ["7351701078250"]}}, rating: {gte: 1}}
      ) {
        totalCount
        sum(field: rating)
      },
      probierpaketReview: allDropsReview(
        sort: {fields: createdAt, order: DESC}
        filter: {curated: {eq: true}, productIds: {ids: {in: ["6619176370359"]}}, rating: {gte: 1}}
      ) {
        totalCount
        sum(field: rating)
      },
      zubehoerReview: allDropsReview(
        sort: {fields: createdAt, order: DESC}
        filter: {curated: {eq: true}, productIds: {ids: {in: ["7351700848874"]}}, rating: {gte: 1}}
      ) {
        totalCount
        sum(field: rating)
      },
    }
  `)

  let swiperParams = {
    loop: true,
    spaceBetween: 0,
    freeMode: true,
    slidesPerView: 'auto',
  };

  return (
    <div>
        <Container className={styles.productCategories}>
          <Row className="d-none d-lg-flex">
            <Col lg={6} className={styles.category}>
              <GatsbyImage objectFit="cover" className={styles.categoryImage} image={data.dropsCategory.childImageSharp.gatsbyImageData} alt="Drops Category" />
              <div className={styles.buyOverlay}>
                <h3>Toothpaste Drops</h3>
                <p>ab 2,95€ / Monat</p>
                <a className={styles.learnMoreCTA} href="/products/monatspaket">Mehr erfahren</a>
              </div>
            </Col>
            <Col lg={6} className={styles.category}>
              <GatsbyImage objectFit="cover" className={styles.categoryImage} image={data.addonsCategory.childImageSharp.gatsbyImageData} alt="Addon Category" />
              <div className={styles.buyOverlay}>
                <h3>Zubehör Box</h3>
                <p>für 12,95€</p>
                <a className={styles.learnMoreCTA} href="/products/zubehoerbox">Mehr erfahren</a>
              </div>
            </Col>
          </Row>
          <Row className="d-block d-lg-none">
            <h2>Unsere Produkte</h2>
            <Swiper {...swiperParams}>
              <SwiperSlide className={styles.swiperSlide}>
                <a href="/products/monatspaket">
                  <GatsbyImage className={styles.sliderImage} objectFit="cover" image={data.dropsCategoryMobile.childImageSharp.gatsbyImageData} alt="Drops Category" />
                  <h3>Toothpaste Drops</h3>
                  <p>ab 2,95€ / Monat</p>
                  <div className={styles.rating}>
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      {(data.monatspaketReview.sum/data.monatspaketReview.totalCount).toFixed(1) >= 1.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                      {(data.monatspaketReview.sum/data.monatspaketReview.totalCount).toFixed(1) >= 2.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                      {(data.monatspaketReview.sum/data.monatspaketReview.totalCount).toFixed(1) >= 3.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                      {(data.monatspaketReview.sum/data.monatspaketReview.totalCount).toFixed(1) >= 4.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                    </div>
                    <span>{data.monatspaketReview.totalCount} Bewertungen</span>
                  </div>
                </a>
              </SwiperSlide>
              <SwiperSlide className={styles.swiperSlide}>
                <a href="/products/zubehoerbox">
                  <GatsbyImage className={styles.sliderImage} image={data.addonsCategoryMobile.childImageSharp.gatsbyImageData} alt="Addon Category" />
                  <h3>Zubehör Box</h3>
                  <p>für 12,95€</p>
                  <div className={styles.rating}>
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      {(data.zubehoerReview.sum/data.zubehoerReview.totalCount).toFixed(1) >= 1.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                      {(data.zubehoerReview.sum/data.zubehoerReview.totalCount).toFixed(1) >= 2.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                      {(data.zubehoerReview.sum/data.zubehoerReview.totalCount).toFixed(1) >= 3.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                      {(data.zubehoerReview.sum/data.zubehoerReview.totalCount).toFixed(1) >= 4.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                    </div>
                    <span>{data.zubehoerReview.totalCount} Bewertungen</span>
                  </div>
                </a>
              </SwiperSlide>
              <SwiperSlide className={styles.swiperSlide}>
                <a href="/products/probierpaket">
                  <GatsbyImage className={styles.sliderImage} objectFit="cover" image={data.probierpaketCategoryMobile.childImageSharp.gatsbyImageData} alt="Drops Category" />
                  <h3>Probierpaket</h3>
                  <p>für 8,95€</p>
                  <div className={styles.rating}>
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      {(data.probierpaketReview.sum/data.probierpaketReview.totalCount).toFixed(1) >= 1.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                      {(data.probierpaketReview.sum/data.probierpaketReview.totalCount).toFixed(1) >= 2.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                      {(data.probierpaketReview.sum/data.probierpaketReview.totalCount).toFixed(1) >= 3.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                      {(data.probierpaketReview.sum/data.probierpaketReview.totalCount).toFixed(1) >= 4.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                    </div>
                    <span>{data.probierpaketReview.totalCount} Bewertungen</span>
                  </div>
                </a>
              </SwiperSlide>
              <SwiperSlide className={styles.swiperSlide}>
                <a href="/products/starterkit">
                  <GatsbyImage className={styles.sliderImage} objectFit="cover" image={data.starterkitCategoryMobile.childImageSharp.gatsbyImageData} alt="Drops Category" />
                  <h3>Starterkit</h3>
                  <p>für 19,95€</p>
                  <div className={styles.rating}>
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      {(data.starterkitReview.sum/data.starterkitReview.totalCount).toFixed(1) >= 1.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                      {(data.starterkitReview.sum/data.starterkitReview.totalCount).toFixed(1) >= 2.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                      {(data.starterkitReview.sum/data.starterkitReview.totalCount).toFixed(1) >= 3.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                      {(data.starterkitReview.sum/data.starterkitReview.totalCount).toFixed(1) >= 4.5 &&
                        <svg xmlns="http://www.w3.org/2000/svg" className={styles.reviewStar} width="20" height="20" viewBox="0 0 24 24"><path fill="#fdd500" d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z"/></svg>
                      }
                    </div>
                    <span>{data.starterkitReview.totalCount} Bewertungen</span>
                  </div>
                </a>
              </SwiperSlide>
            </Swiper>
          </Row>
        </Container>
		</div>
  )
}
