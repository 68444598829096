import React from "react"

import * as styles from "./header.module.scss"

import Container from 'react-bootstrap/Container'

export default function IndexHeader(props) {

  return (
    <div>
	    <div className={styles.indexHeader}>
        <Container className={styles.innerIndexHeader}>
          <div>
            <h1>Ohne Plastik.</h1>
            <h2>Mit Drops.</h2>
            <p>Zahnpflege wie du sie noch nicht erlebt hast.<br />So einfach kann plastikfrei sein.</p>
            <a href="/products/starterkit" className={styles.ctaBtn}>Jetzt Kaufen</a>
          </div>
        </Container>
      </div>
      <div className={styles.headerMainMessage}>
        <p>Nachhaltig lächeln ohne Kompromisse,<br />mit gutem Gewissen.</p>
      </div>
		</div>
  )
}
