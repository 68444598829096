// extracted by mini-css-extract-plugin
export var kitSection = "starterKit-module--kitSection--5Wkim";
export var logoImg = "starterKit-module--logoImg--91+eJ";
export var kitTitle = "starterKit-module--kitTitle--YF5cl";
export var kitDescription = "starterKit-module--kitDescription--cv7NK";
export var keypointsSection = "starterKit-module--keypointsSection--KTTFP";
export var keypointRow = "starterKit-module--keypointRow--9y6P1";
export var checkmark = "starterKit-module--checkmark--+JTDC";
export var viewMoreCTA = "starterKit-module--viewMoreCTA--JowiT";
export var CTABody = "starterKit-module--CTABody--95PZD";
export var CTALeft = "starterKit-module--CTALeft--fTcFX";
export var mobileBorder = "starterKit-module--mobileBorder--cSwQB";
export var mobileImg = "starterKit-module--mobileImg--zBX09";
export var mobileColumn = "starterKit-module--mobileColumn--dGU14";