// extracted by mini-css-extract-plugin
export var reviewSection = "reviewSection-module--reviewSection--bijID";
export var ratingHead = "reviewSection-module--ratingHead--nBMUx";
export var reviewStar = "reviewSection-module--reviewStar--N1y53";
export var reviewsBox = "reviewSection-module--reviewsBox--CR7FV";
export var reviewContent = "reviewSection-module--reviewContent--0hBuv";
export var reviewTitle = "reviewSection-module--reviewTitle--PLRN7";
export var reviewStars = "reviewSection-module--reviewStars--EAs14";
export var faStar = "reviewSection-module--faStar--mQNPm";
export var reviewCreator = "reviewSection-module--reviewCreator--NnbsN";
export var swiperSlide = "reviewSection-module--swiperSlide--FciSn";
export var reviewText = "reviewSection-module--reviewText--Yo5sT";